<template>
  <div>
    <headertop Inv="2" ref="headertop"></headertop>
    <div class="car" v-loading="loading">
      <div class="goodslist">
        <div class="yunsong">
          <div class="label">配送方式</div>
          <el-select v-model="form.deliveryType" size="small" placeholder="请选择">
            <el-option v-for="item in option.delivery" :key="item.useType" :label="item.name" :value="item.useType"
              @click.native="changeYunSong(item)">
            </el-option>
          </el-select>
          <div class="label" style="margin-left: 20px;">支付方式</div>
          <el-select v-model="form.payType" size="small" placeholder="请选择">
            <el-option v-for="item in option.pay" :key="item.useType" :label="item.name" :value="item.useType">
            </el-option>
          </el-select>
          <div class="label" style="margin-left: 20px;" v-if="useType === 0">地址</div>
          <el-select v-model="form.addressId" size="small" placeholder="请选择" v-if="useType === 0">
            <el-option v-for="item in addressList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button type="primary" @click="toAdd" size="small" style="margin-left: 20px;"
            v-if="useType === 0">新增地址</el-button>
        </div>
        <div class="yunsong zitidian" v-if="form.deliveryType == 2">
          <div>自提点信息：</div>
          <div>负责人：{{ zitiForm.linkName }}</div>
          <div>联系方式：{{ zitiForm.linkPhone }}</div>
          <div>地址：{{ zitiForm.address }}</div>
          <div>工作时间：{{ zitiForm.workTime }}</div>
        </div>
        <el-empty description="暂无数据" v-if="list.length == 0"></el-empty>

        <div class="list" v-for="(item, index) in list" :key="index">
          <el-image class="goodsImg" :src="item.good.goodPic" fit="cover "></el-image>
          <div class="text">
            <div class="name">
              <div class="names">{{ item.good.goodName }}</div>
              <div>
                <img src="../assets/del.png" class="del" alt="" @click="del(item)" />
              </div>
            </div>
            <div class="rightbixs">
              <div class="topline">
                <div>单位</div>
                <div>属性</div>
                <div>价格</div>
                <div>折扣</div>
                <div>实际价格</div>
                <div>数量</div>
                <div>小计</div>
              </div>

              <div class="topline colorred" style="margin-top:10px">
                <div>{{ item.unitName }}</div>
                <div>{{ item.proValue? item.proValue:' - ' }}</div>
                <div>{{currency.symbol}}{{ item.sellPrice }}</div>
                <div>{{currency.symbol}}{{ item.discount }}</div>
                <div>{{currency.symbol}}{{ item.realPrice }}</div>
                <div><el-input-number size="mini" v-model="item.goodNum" @change="handleChange(item)"
                    :min="1"></el-input-number></div>
                <div>{{currency.symbol}}{{ item.itemPrice }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="heji">
          <span>合计金额:<span class="pri" style="font-size: 20px">{{ allRealPrice }}</span></span>
          <span>折扣金额: <span class="pri" style="font-size: 20px">{{ allDiscountPrice }}</span></span>
          <span>实收金额: <span class="pri" style="font-size: 20px">{{ allPrice }}</span></span>

          <el-button type="primary" @click="toPay" style="margin-top:20px;margin-right: 15px;">下一步</el-button>
        </div>
      </div>
    </div>
    <bottom></bottom>
    <el-dialog title="" :visible.sync="dialogVisible" width="40%">
      <!-- sandbox -->
      <!-- env="production" -->
      <div v-if="overData.payType == 2 || overData.payType == 1" style="display: flex;justify-content: center;">
        <span style="text-align:center;font-size: 24px;">订单已完成</span>
      </div>
      <div v-if="overData.payType == 0">
        <PayPal :amount="allPrice + ''" currency="AUD" :client="credentials" :env="env" :button-style="buttonStyle"
          @payment-authorized="paymentAuthorized" @payment-completed="paymentCompleted"
          @payment-cancelled="paymentCancelled">
        </PayPal>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import headertop from "../components/header.vue";
import bottom from "../components/bottom.vue";

import {
  cartget,
  cartdelete,
  cartcartNextSet,
  cartclear,
  cartupdate,
  onlineget
} from "@/request/api.js";
import PayPal from "vue-paypal-checkout";

export default {
  name: "home",
  components: {
    headertop,
    bottom,
    PayPal
  },
  data() {
    return {
      currency:{},
      overData: {},
      form: {
        deliveryType: '',
        payType: '',
        addressId: '',
      },
      amount: "1",
      env: 'sandbox',
      credentials: {
        sandbox: "AYU3Ok_40gXENzZb5FOLrAgN57uMLp9MOaOFmRNIrPoBb0fVWDqKOQomeRAr-m5uBvaj3mDFMoa6e_Ny",
        production: "AdUzSCCq1rNlSYM9ca2q6M75BNW9moJADOU1VHl631tsdJPhDY-n8JdMimbpLp8iHfJ-jax64i9DqqqK",
      },
      buttonStyle: {
        label: "pay",
        size: "responsive",
        shape: "pill",
        color: "blue",
      },
      addressList: [],
      dialogVisible: false,
      num: 1,
      list: [],
      allCheck: false,
      option: {},
      allPrice: 0,
      zhekouPrice: 0,
      shishouPrice: 0,
      loading: true,
      useType: '',
      zitiForm: {},
      allRealPrice:0,
			allDiscountPrice:0,
    };
  },
  created() {
    this.getData();
    this.getPaySet()
    this.getAddress()
    onlineget().then(res=>{
      this.currency = res.data.currency
    })
    
  },
  mounted() { },

  methods: {
    getAddress() {
      this.$api.memberUserAddresslist().then(res => {
        console.log('地址')
        console.log(res)
        this.addressList = res.rows
      })
    },
    getPaySet() {
      this.$api.onlineconfig().then(res => {
        console.log('支付的參數')
        console.log(res)
        this.option = res.data
        this.credentials.sandbox = res.data.payPalConfig.sandClientId
        this.credentials.production = res.data.payPalConfig.proClientId
        this.env = res.data.payPalConfig.env
      })
    },
    getData() {
      cartget().then((res) => {
        console.log(res);
        this.loading = false;
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            if (!item.discount) {
              item.discount = 0;
            }
            if (item.good.goodPic) {
              item.good.goodPic = item.good.goodPic.split(",");
            }

          });
        }
        this.list = res.data.cartList;
        this.allRealPrice = res.data.allRealPrice 
				this.allDiscountPrice = res.data.allDiscountPrice 
				this.allPrice = res.data.allPrice 
        // this.checkPrice()
      });
    },
    changeYunSong(e) {
      this.useType = e.useType
      this.zitiForm = JSON.parse(e.params)
    },
    toPay() {
      console.log(this.form)
      if (this.form.deliveryType === '') {
        return this.$message.warning('请选择运送方式')
      }

      if (this.form.payType === '') {
        return this.$message.warning('请选择支付方式')
      }

      if (this.useType === 0) {
        if (this.form.addressId === '') {
          return this.$message.warning('请选择上门地址')
        }
      }

      this.$api.cartNextSet(this.form).then(res => {
        console.log(this.form)
        // this.overData = res.data
        // this.dialogVisible = true
        this.$router.push({
          path: `/pay`,
        });
      })

      // if (this.list.length > 0) {
      //   let data = [];

      //   this.list.forEach((item) => {
      //     data.push(item.cartId);
      //   });

      //   cartcartNextSet(data).then((res) => {

      //   });
      // }
    },
    toAdd() {
      this.$router.push({
        path: `/my?Inv=2`,
      });
    },
    handleChange(e) {
      cartupdate(e).then(res => {
        console.log(res)
        this.getData()
        this.$refs.headertop.getPrice()

      })
    },
    del(e) {
      cartdelete(e.cartId).then((res) => {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
        this.getData();
        this.$refs.headertop.getPrice()
        this.allCheck = false;
        this.allPrice = 0;
      });
    },
    add() {

    },
    checkPrice() {
      if (this.list.length > 0) {
        let pri = [];
        let zhekou = [];
        let shishou = [];
        this.list.forEach((item) => {
          pri.push(this.$math.bignumber(item.itemSellPrice));
          zhekou.push(this.$math.bignumber(item.itemDiscount));
          shishou.push(this.$math.bignumber(item.itemPrice));
        });

        if (pri.length == 0) {
          this.allPrice = 0
        } else if (pri.length == 1) {
          this.allPrice = pri[0]
        } else {
          this.allPrice = this.$math.format(
            this.$math.add(
              ...pri
            )
          );
        }

        if (zhekou.length == 0) {
          this.zhekouPrice = 0
        } else if (zhekou.length == 1) {
          this.zhekouPrice = zhekou[0]
        } else {
          this.zhekouPrice = this.$math.format(
            this.$math.add(
              ...zhekou
            )
          );
        }

        if (shishou.length == 0) {
          this.shishouPrice = 0
        } else if (shishou.length == 1) {
          this.shishouPrice = shishou[0]
        } else {
          this.shishouPrice = this.$math.format(
            this.$math.add(
              ...shishou
            )
          );
        }

      } else {
        this.allPrice = 0
        this.zhekouPrice = 0
        this.shishouPrice = 0
      }
    },
    clearCar() {
      this.$confirm("清空购物车, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        cartclear().then((res) => {
          this.getData();
          this.allPrice = 0;
          this.zhekouPrice = 0;
          this.shishouPrice = 0;
          this.$refs.headertop.getPrice()


          this.$notify({
            title: "成功",
            message: "清空成功",
            type: "success",
          });
        });
      });
    },
    paymentCompleted(data) {
      console.log('支付成功的回调')
      console.log(data)
      //支付成功
      let query = {
        orderNum: this.overData.orderId,
        paymentId: data.id
      };
      this.$api.orderpaySuccessOrder(query).then((res) => {
        this.$notify({
          title: "成功",
          message: "支付成功",
          type: "success",
        });
        this.$router.push({
          path: `/`,
        });
      });
    },
    paymentAuthorized(data) {
      // 授权完成的回调，可以拿到订单id
      console.log("授权完成的回调");
      console.log(data);
    },
    paymentCancelled(data) {
      console.log('取消交易的回调')
      console.log(data)
      // 用户取消交易的回调

    },
  },
};
</script>

<style lang="scss" scoped>
.yunsong {
  display: flex;
  align-items: center;
  padding-left: 60px;
  margin-bottom: 20px;

  .label {
    margin-right: 10px;
    font-size: 14px;
  }
}

.heji {
  padding-top: 20px;
  border-top: 1px solid #f5f5f5;
  color: #8d8d8d;
  font-size: 15px;
  text-align: right;

  &>span {
    display: block;
    margin-bottom: 4px;
  }

  .pri {
    font-size: 26px;
    margin-left: 10px;
    color: #e70012;
    font-weight: bold;
    margin-right: 20px;
  }
}

.rightbixs {
  margin-top: 15px;

  .topline {
    display: flex;
    align-items: center;

    div {
      width: 120px;
      margin-right: 20px;
    }
  }

  .colorred {
    div {
      color: #e70012;
    }
  }
}

.zitidian {
  div {
    font-size: 14px;
    margin-right: 20px;
  }
}

.car {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background: #f5f2f2;
  margin-top: 20px;

  .goodslist {
    background: #fff;
    border-radius: 5px;
    padding: 10px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 60px;
      cursor: pointer;
      position: relative;

      .left {
        display: flex;
        align-items: center;

        img {
          width: 25px;
          margin-right: 8px;
        }
      }
    }

    .list {
      position: relative;
      padding-left: 60px;
      display: flex;
      padding-right: 30px;
      cursor: pointer;
      padding-bottom: 20px;

      .text {
        width: 100%;
        transform: translateY(-6px);
      }

      .price {
        font-size: 24px;
        color: #e70012;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 15px;
        color: #5e5e5e;
        margin-bottom: 10px;
      }

      .guige {
        margin-top: 5px;
        font-size: 14px;
        color: #a9a7a7;
        margin-bottom: 50px;

        .gui {
          background: #e9e9e9;
          margin-left: 10px;
          padding: 6px 20px;
          border-radius: 5px;
          font-size: 16px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .names {
          font-size: 20px;
          color: #2c2a2a;
        }

        .del {
          width: 30px;
        }

        .price {}
      }

      .xuanzhe {
        width: 20px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      .goodsImg {
        width: 170px;
        margin-right: 20px;
        height: 170px;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;

    .clear {
      position: absolute;
      right: 10px;
    }

    img {
      width: 20px;
      margin-right: 10px;
    }
  }
}
</style>
